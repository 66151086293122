import React from 'react';
import TokenDisplay from '../TokenDisplay';
import collectionAddresses from '../collections/collectionAddresses';
import empty_bg from '../img/empty_bg.gif';

const RecentCollections = () => {
  return (
    <>
      <div className="row mb-5">
        <div className="col-12 text-center">
          <h1 className="display-4 mb-3">Featured Collections</h1>
          <p className="lead text-own">
            Discover amazing NFT collections from talented artists around the Cosmos ecosystem. 
            <br />Each collection featured here is vetted and supported by our platform.
          </p>
          <div className="d-flex justify-content-center align-items-center gap-2 mt-4">
            <span className="badge bg-dark">Current Collections: {collectionAddresses.length}</span>
            <span className="badge custom-border text-own">More Coming Soon</span>
          </div>
        </div>
      </div>

      <div className="row custom-portfolio">
        <div className="col-12">
          <h4 className='mb-4'><i className="bi bi-collection me-2"></i>Browse Collections</h4>
          <div className="d-flex flex-wrap">
            {collectionAddresses.map((collection, index) => (
              collection.collectionAddr ? (
                <TokenDisplay
                  key={index}
                  starsAddress=""
                  collectionAddr={collection.collectionAddr}
                  title={collection.title}
                  cover={collection.cover}
                  link={collection.link}
                  multiplier={collection.multiplier}
                  updateTotalCount={() => {}}
                  updateIsOwned={() => {}}
                  hideTokenCount={true}
                  hideMultiplier={true}
                />
              ) : null
            ))}
            <div className="col-5 col-md-2 float-start">
              <article className={'card text-center custom-border border-bottom-0'}>
                <div className="card-body bg-light p-0 d-flex flex-column">
                  <img className="rounded-top img-fluid" src={empty_bg} alt="empty" loading="lazy" />
                  <div className="card-body p-0 d-flex flex-column">
                    <p className="card-title fw-bold mt-4 mb-5 fs-5">Your collection</p>
                    <a href="/#/apply" className="btn bg-dark col-12 text-white rounded-top-0" role="button">Apply!</a>                        
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentCollections;

