import { shortenAddress, copyToClipboard, endSession, isWalletAvailable } from './walletUtils';

const checkSolflareConnection = async () => {
  try {
    if (!window.solflare) return false;
    return window.solflare.isConnected;
  } catch (error) {
    return false;
  }
};

export const connectWallet = async (setStarsAddress, showMessageCallback) => {
  if (!isWalletAvailable('Solflare')) {
    showMessageCallback?.("Please install Solflare wallet", "warning");
    return;
  }
  
  try {
    // Check if already connected
    const isConnected = await checkSolflareConnection();
    if (!isConnected) {
      await window.solflare.connect();
    }

    const address = window.solflare.publicKey?.toString();
    if (!address) {
      throw new Error('No public key available');
    }

    setStarsAddress(address);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('activeWallet', 'Solflare');
  } catch (error) {
    console.error('Error connecting to Solflare:', error);
    showMessageCallback?.("Failed to connect to Solflare wallet", "error");
  }
};

export const proveAccountOwnership = async (setStarsAddress, setOwnershipProved, showMessageCallback) => {
  if (!window.solflare) {
    showMessageCallback("Please install Solflare wallet", "warning");
    return;
  }

  try {
    await window.solflare.connect();
    const address = await window.solflare.publicKey.toString();
    const message = "Prove account ownership";
    const encodedMessage = new TextEncoder().encode(message);
    const signature = await window.solflare.signMessage(encodedMessage);
    
    setStarsAddress(address);
    setOwnershipProved(true);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('ownershipProved', 'true');
    sessionStorage.setItem('activeWallet', 'Solflare');
    showMessageCallback("Account ownership proved successfully!", "success");
  } catch (error) {
    console.error('Error proving Solflare account ownership:', error);
    setOwnershipProved(false);
    sessionStorage.setItem('ownershipProved', 'false');
    showMessageCallback("Error proving Solflare account ownership", "error");
  }
};

export { shortenAddress, copyToClipboard, endSession };
