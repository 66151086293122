import React, { useState, useRef, useEffect, useCallback } from 'react';
import mergeImages from 'merge-images';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import JSZip from 'jszip';
import axios from 'axios';
import { BADART_URL } from '../config';
import Toast from '../components/Toast';
import ConfirmationToast from '../components/ConfirmationToast';
import useLocalStorage from '../hooks/useLocalStorage';
import { FileUpload } from 'primereact/fileupload';

const Randomizer = ({ credits = 0 }) => {  // Add credits prop
    const [groups, setGroups] = useLocalStorage('trait-groups', []);
    const [mergedImages, setMergedImages] = useState([]);
    const canvasRef = useRef(null);
    const [progress, setProgress] = useState({ current: 0, total: 0, isProcessing: false });
    const [expandedGroups, setExpandedGroups] = useState({});
    const [downloadProgress, setDownloadProgress] = useState({ current: 0, total: 0, isProcessing: false });
    const BATCH_SIZE = 50; // Number of images to process at once
    const IMAGES_PER_ZIP = 100; // Number of images per zip file
    const [error, setError] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [staleFiles, setStaleFiles] = useState(false);
    const [metadataProgress, setMetadataProgress] = useState({ current: 0, total: 0, isProcessing: false });
    const METADATA_PER_ZIP = 1000; // Number of JSON files per zip

    const addNewGroup = () => {
        setGroups([...groups, {
            id: Date.now(),
            name: '',
            files: [],
            traitValues: {}
        }]);
    };

    const handleFileUpload = (groupId, event) => {
        setStaleFiles(false); // Reset stale files warning
        const files = event.files;
        const newFiles = files.map(file => ({
            name: file.name,
            url: URL.createObjectURL(file)
        }));

        setGroups(groups.map(group => {
            if (group.id === groupId) {
                const newTraitValues = { ...group.traitValues };
                files.forEach(file => {
                    newTraitValues[file.name] = file.name.split('.')[0];
                });
                return {
                    ...group,
                    files: [...group.files, ...newFiles],
                    traitValues: newTraitValues
                };
            }
            return group;
        }));
    };

    const handleGroupNameChange = (groupId, name) => {
        setGroups(groups.map(group => 
            group.id === groupId ? { ...group, name } : group
        ));
    };

    const handleTraitValueChange = (groupId, fileName, value) => {
        setGroups(groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    traitValues: {
                        ...group.traitValues,
                        [fileName]: value
                    }
                };
            }
            return group;
        }));
    };

    const handleDeleteGroup = (groupId) => {
        setGroups(groups.filter(group => group.id !== groupId));
    };

    const handleDeleteImage = (groupId, fileName) => {
        setGroups(groups.map(group => {
            if (group.id === groupId) {
                const newFiles = group.files.filter(file => file.name !== fileName);
                const newTraitValues = { ...group.traitValues };
                delete newTraitValues[fileName];
                return {
                    ...group,
                    files: newFiles,
                    traitValues: newTraitValues
                };
            }
            return group;
        }));
    };

    const generateCombinations = (arrays) => {
        if (arrays.length === 0) return [[]];
        const [first, ...rest] = arrays;
        const restCombos = generateCombinations(rest);
        return first.flatMap(item => restCombos.map(combo => [item, ...combo]));
    };

    const calculateTotalCombinations = (arrays) => {
        if (arrays.length === 0) return 0;
        return arrays.reduce((acc, curr) => acc * curr.length, 1);
    };

    // Add this new function to get random combinations
    const getRandomCombinations = (combinations, limit) => {
        const shuffled = [...combinations].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, limit);
    };

    // Add this new function to check if an image URL is valid
    const isImageUrlValid = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return blob.type.startsWith('image/');
        } catch (error) {
            return false;
        }
    };

    // Modify validateFileUrls to actually check the URLs
    const validateFileUrls = useCallback(async () => {
        let hasStaleUrls = false;
        const updatedGroups = [...groups];
        
        for (let groupIndex = 0; groupIndex < updatedGroups.length; groupIndex++) {
            const validFiles = [];
            for (let file of updatedGroups[groupIndex].files) {
                const isValid = await isImageUrlValid(file.url);
                if (isValid) {
                    validFiles.push(file);
                } else {
                    hasStaleUrls = true;
                }
            }
            updatedGroups[groupIndex] = {
                ...updatedGroups[groupIndex],
                files: validFiles
            };
        }

        if (hasStaleUrls) {
            setStaleFiles(true);
            setToastMessage('Some files were removed due to expired URLs. Please re-upload the images. For privacy reasons, we do not store any image data on our servers.');
            setToastType('warning');
            setGroups(updatedGroups);
        }
    }, [groups, setGroups]);

    // Modify useEffect to handle async validation
    useEffect(() => {
        validateFileUrls();
    }, [validateFileUrls]);

    const mergeLayers = async () => {
        setMergedImages([]);
        setProgress({ current: 0, total: 0, isProcessing: false });
        setError(null);

        // Validate URLs before proceeding
        await validateFileUrls();

        if (staleFiles) {
            setToastMessage('Some images are no longer available. Please re-upload them before generating.');
            setToastType('error');
            return;
        }

        try {
            const groupFiles = groups
                .filter(group => group.files.length > 0)
                .map(group => group.files);

            if (groupFiles.length === 0) return;

            // Generate all possible combinations
            const allCombinations = generateCombinations(groupFiles);
            const totalPossibleCombinations = allCombinations.length;

            // Determine how many combinations to generate
            let combinationsToGenerate = allCombinations;
            if (totalPossibleCombinations > credits) {
                combinationsToGenerate = getRandomCombinations(allCombinations, credits);
                setError(`Limited to ${credits} random combinations out of ${totalPossibleCombinations} possible combinations.`);
                setToastMessage(`Generation limited to ${credits} combinations due to available credits. Total possible combinations: ${totalPossibleCombinations}`);
                setToastType('warning');
            }
            
            setProgress({ current: 0, total: combinationsToGenerate.length, isProcessing: true });

            const mergedResults = [];
            for (let i = 0; i < combinationsToGenerate.length; i++) {
                const imagesToMerge = combinationsToGenerate[i].map(file => ({
                    src: file.url,
                    x: 0,
                    y: 0
                }));
                const result = await mergeImages(imagesToMerge);
                mergedResults.push(result);
                setProgress(prev => ({ ...prev, current: i + 1 }));
            }

            setMergedImages(mergedResults);
            setProgress(prev => ({ ...prev, isProcessing: false }));

        } catch (error) {
            console.error('Error merging images:', error);
            setProgress(prev => ({ ...prev, isProcessing: false }));
            setToastMessage('Error generating NFT files');
            setToastType('error');
        }
    };

    const saveImage = async (dataUrl, fileName) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${fileName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const saveJson = async (index) => {
        const metadata = {
            name: `${name} #${index + 1}`,
            description: description,
            attributes: []
        };

        // Collect traits from each group
        groups.forEach(group => {
            if (!group.name) return;
            
            const combinationIndex = index % group.files.length;
            const file = group.files[combinationIndex];
            if (file && group.traitValues[file.name]) {
                metadata.attributes.push({
                    [group.name]: group.traitValues[file.name]
                });
            }
        });

        // Create and trigger download
        const dataStr = JSON.stringify(metadata, null, 2);
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${index + 1}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const downloadAllImages = async () => {
        setDownloadProgress({ current: 0, total: mergedImages.length, isProcessing: true });

        try {
            // Split images into chunks
            for (let startIndex = 0; startIndex < mergedImages.length; startIndex += IMAGES_PER_ZIP) {
                const zip = new JSZip();
                const endIndex = Math.min(startIndex + IMAGES_PER_ZIP, mergedImages.length);
                const currentBatch = mergedImages.slice(startIndex, endIndex);
                
                // Process each image in the current batch
                await Promise.all(currentBatch.map(async (dataUrl, batchIndex) => {
                    const imageData = dataUrl.split(',')[1];
                    const index = startIndex + batchIndex;
                    zip.file(`merged_${index + 1}.png`, imageData, {base64: true});
                    setDownloadProgress(prev => ({ ...prev, current: index + 1 }));
                }));

                // Generate and download current zip
                const content = await zip.generateAsync({
                    type: "blob",
                    compression: "DEFLATE",
                    compressionOptions: { level: 6 }
                });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(content);
                const zipNumber = Math.floor(startIndex / IMAGES_PER_ZIP) + 1;
                const totalZips = Math.ceil(mergedImages.length / IMAGES_PER_ZIP);
                link.download = `merged_images_${zipNumber}_of_${totalZips}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

                // If there are more files to process, wait a bit before next batch
                if (endIndex < mergedImages.length) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        } catch (error) {
            console.error('Error creating zip files:', error);
        } finally {
            setDownloadProgress(prev => ({ ...prev, isProcessing: false }));
        }
    };

    const generateMetadata = async () => {
        if (mergedImages.length === 0) {
            setError("Please generate NFT files first");
            return;
        }

        setMetadataProgress({ current: 0, total: mergedImages.length, isProcessing: true });

        try {
            // Split metadata into chunks
            for (let startIndex = 0; startIndex < mergedImages.length; startIndex += METADATA_PER_ZIP) {
                const zip = new JSZip();
                const endIndex = Math.min(startIndex + METADATA_PER_ZIP, mergedImages.length);
                const currentBatch = mergedImages.slice(startIndex, endIndex);
                
                // Process each metadata in the current batch
                currentBatch.forEach((_, batchIndex) => {
                    const index = startIndex + batchIndex;
                    const metadata = {
                        name: `${name} #${index + 1}`,
                        description: description,
                        attributes: []
                    };

                    // Collect traits from each group
                    groups.forEach(group => {
                        if (!group.name) return;
                        
                        const combinationIndex = index % group.files.length;
                        const file = group.files[combinationIndex];
                        if (file && group.traitValues[file.name]) {
                            metadata.attributes.push({
                                [group.name]: group.traitValues[file.name]
                            });
                        }
                    });

                    zip.file(`${index + 1}.json`, JSON.stringify(metadata, null, 2));
                    setMetadataProgress(prev => ({ ...prev, current: index + 1 }));
                });

                // Generate and download current zip
                const content = await zip.generateAsync({ type: "blob" });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(content);
                const zipNumber = Math.floor(startIndex / METADATA_PER_ZIP) + 1;
                const totalZips = Math.ceil(mergedImages.length / METADATA_PER_ZIP);
                link.download = `metadata_${zipNumber}_of_${totalZips}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

                // If there are more files to process, wait a bit before next batch
                if (endIndex < mergedImages.length) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        } catch (error) {
            console.error('Error generating metadata:', error);
            setToastMessage('Error generating metadata files');
            setToastType('error');
        } finally {
            setMetadataProgress(prev => ({ ...prev, isProcessing: false }));
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        
        const items = Array.from(groups);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setGroups(items);
    };

    const handleFileReorder = (groupId, source, destination) => {
        if (!destination) return;

        setGroups(groups.map(group => {
            if (group.id === groupId) {
                const files = Array.from(group.files);
                const [reorderedItem] = files.splice(source.index, 1);
                files.splice(destination.index, 0, reorderedItem);
                return { ...group, files };
            }
            return group;
        }));
    };

    const toggleGroupExpanded = (groupId) => {
        setExpandedGroups(prev => ({
            ...prev,
            [groupId]: !prev[groupId]
        }));
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column mt-2">
                <span className="text-muted">
                    or drag and drop images here
                </span>
            </div>
        );
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{ 
                backgroundColor: 'transparent', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.5rem'
            }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    return (
        <div className="container-fluid mt-2 mb-5 pb-5">
            <Toast 
                message={toastMessage} 
                type={toastType} 
                onClose={() => {
                    setToastMessage(null);
                    setToastType(null);
                }}
            />
            
            {staleFiles && (
                <div className="alert alert-warning mb-4">
                    <i className="bi bi-exclamation-triangle me-2"></i>
                    Some uploaded files are no longer available. Please re-upload the missing images.
                    <hr/>
                    <small className="text-muted">
                        <i className="bi bi-info-circle me-2"></i>
                        For privacy and security reasons, we do not store any image data on our servers. Images are temporarily processed in your browser only.
                    </small>
                </div>
            )}

            {/* Add name and description fields at the top */}
            <div className="row mb-4">
                <div className="col-md-6">
                    <div className="custom-border rounded p-3">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="e.g., My NFT"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <small className="text-muted"># will be automatically added</small>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="custom-border rounded p-3">
                        <label className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            placeholder="Enter collection description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows="2"
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col custom-border rounded p-3">
                        <button 
                            className="btn btn-dark float-end"
                            onClick={addNewGroup}
                        >
                        <i className="bi bi-plus-circle me-2"></i>
                        Add New Trait Group
                    </button>              
                    <div className='pt-0 rounded'>
                        <div className="text-dark fw-bold">
                        <span className='badge bg-success fs-6 me-2 ms-2'>
                            <i className="bi bi-check-all me-1"></i> 
                            {credits}
                            </span> 
                            available credits

                            {groups.length > 0 && (
                                    <span className="badge bg-danger fs-6 p-0 rounded p-2 ms-2">
                                        {calculateTotalCombinations(
                                            groups
                                                .filter(group => group.files.length > 0)
                                                .map(group => group.files)
                                        )}
                                        
                                        </span>
                            )} possible combinations
                            <small className="text-muted ms-2">(Max: 10 000)</small>
                        </div>
                    </div>
                </div>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="trait-groups" type="group" direction="horizontal">
                    {(provided) => (
                        <div 
                            className="row g-4" 
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            {groups.length === 0 ? (
                                <div className="col-12">
                                    <div className="alert alert-info">
                                        <i className="bi bi-info-circle me-2"></i>
                                        Start by adding a trait group (Background, Body)
                                    </div>
                                </div>
                            ) : (
                                groups.map((group, index) => (
                                    <Draggable 
                                        key={group.id} 
                                        draggableId={group.id.toString()} 
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div 
                                                className="col-md-4"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    // Add these styles to maintain grid during drag
                                                    position: snapshot.isDragging ? 'relative' : 'relative',
                                                    zIndex: snapshot.isDragging ? 9999 : 'auto',
                                                }}
                                            >
                                                <div className="card bg-transparent custom-border h-100">
                                                    <div 
                                                        className="card-header bg-white fw-bold d-flex justify-content-between align-items-center"
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <span className="d-flex align-items-center">
                                                            <i className="bi bi-grip-vertical me-2"></i>
                                                            Layer {index + 1}
                                                        </span>
                                                        <div className="d-flex align-items-center">
                                                            <button 
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => handleDeleteGroup(group.id)}
                                                                title="Delete group"
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                <span>Trait Group Name</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={group.name}
                                                                onChange={(e) => handleGroupNameChange(group.id, e.target.value)}
                                                                placeholder="e.g., Background, Body, Eyes"
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Upload Images
                                                                <small className="text-muted ms-2">
                                                                    (PNG, GIF files recommended)
                                                                </small>
                                                            </label>

                                                            <FileUpload
                                                                name="files[]"
                                                                multiple
                                                                accept="image/*"
                                                                headerTemplate={headerTemplate}
                                                                emptyTemplate={emptyTemplate}
                                                                chooseLabel="Browse"
                                                                className="w-100"
                                                                customUpload
                                                                uploadHandler={(e) => {
                                                                    handleFileUpload(group.id, e);
                                                                    e.options.clear();
                                                                }}
                                                                auto
                                                            />
                                                        </div>

                                                        {group.files.length > 0 && (
                                                            <div className="uploaded-files bg-light p-3 rounded">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <label className="form-label mb-0 fw-bold">
                                                                    <span className="badge bg-primary p-2 ps-3 pe-3 fs-6">
                                                                    {group.files.length}
                                                                </span> Uploaded Images
                                                                    </label>
                                                                   

                                                                    <button
                                                                        className="btn btn-sm btn-outline-secondary"
                                                                        onClick={() => toggleGroupExpanded(group.id)}
                                                                    >
                                                                        {expandedGroups[group.id] ? (
                                                                            <><i className="bi bi-chevron-up"></i> Hide</>
                                                                        ) : (
                                                                            <><i className="bi bi-chevron-down"></i> Show</>
                                                                        )}
                                                                    </button>

                                                                </div>
                                                                {expandedGroups[group.id] && (
                                                                    <DragDropContext
                                                                        onDragEnd={(result) => {
                                                                            if (!result.destination) return;
                                                                            handleFileReorder(
                                                                                group.id,
                                                                                result.source,
                                                                                result.destination
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Droppable droppableId={`files-${group.id}`}>
                                                                            {(provided) => (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                >
                                                                                    {group.files.map((file, index) => (
                                                                                        <Draggable
                                                                                            key={file.name}
                                                                                            draggableId={`${group.id}-${file.name}`}
                                                                                            index={index}
                                                                                        >
                                                                                            {(provided) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    className="mt-3 bg-white border rounded p-2"
                                                                                                >
                                                                                                    <div className="input-group">
                                                                                                        <div
                                                                                                            {...provided.dragHandleProps}
                                                                                                            className="d-flex align-items-center me-2"
                                                                                                        >
                                                                                                            <i className="bi bi-grip-vertical text-muted"></i>
                                                                                                        </div>
                                                                                                        <div className="image-preview-container">
                                                                                                            <img 
                                                                                                                src={file.url} 
                                                                                                                alt={file.name} 
                                                                                                                className="rounded border me-2"
                                                                                                                style={{height: '40px', width: '40px', objectFit: 'contain'}}
                                                                                                            />
                                                                                                            <div className="image-preview-hover">
                                                                                                                <img 
                                                                                                                    src={file.url} 
                                                                                                                    alt={file.name} 
                                                                                                                    style={{
                                                                                                                        width: '200px',
                                                                                                                        height: '200px',
                                                                                                                        objectFit: 'contain'
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            value={group.traitValues[file.name] || ''}
                                                                                                            onChange={(e) => handleTraitValueChange(group.id, file.name, e.target.value)}
                                                                                                            placeholder="Enter trait value"
                                                                                                        />
                                                                                                        <button
                                                                                                            className="btn btn-outline-danger"
                                                                                                            onClick={() => handleDeleteImage(group.id, file.name)}
                                                                                                            title="Delete image"
                                                                                                        >
                                                                                                            <i className="bi bi-x-lg"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {groups.length > 0 && (
                <div className="row mt-4">
                    <div className="col-12 text-center">

                        {error && (
                            <div className="alert alert-warning mb-3">
                                <i className="bi bi-info-circle me-2"></i>
                                {error}
                            </div>
                        )}

                        <button 
                            className="btn btn-dark px-4 mb-4"
                            onClick={mergeLayers}
                            disabled={progress.isProcessing || error}
                        >
                            <i className="bi bi-layers me-2"></i>
                            Generate NFT Files
                        </button>
                        
                        {progress.isProcessing && (
                            <div className="mt-3">
                                <div className="progress mb-2">
                                    <div 
                                        className="progress-bar progress-bar-striped progress-bar-animated" 
                                        role="progressbar" 
                                        style={{ 
                                            width: `${(progress.current / progress.total) * 100}%` 
                                        }}
                                    />
                                </div>
                                <div className="text-muted">
                                    Generating combinations: {progress.current} of {progress.total}
                                    {progress.total > 0 && (
                                        <span className="ms-2">
                                            ({Math.round((progress.current / progress.total) * 100)}%)
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}


            {mergedImages.length > 1 && (
                <div className="mt-4 text-center custom-border rounded p-3">
                    <button 
                        className="btn btn-dark float-start"
                        onClick={generateMetadata}
                        disabled={metadataProgress.isProcessing}
                    >
                        <i className="bi bi-filetype-json"></i>
                        Generate Metadata (JSON)
                    </button>
                    <button 
                        className="btn btn-dark float-end"
                        onClick={downloadAllImages}
                        disabled={downloadProgress.isProcessing}
                    >
                        <i className="bi bi-file-earmark-zip"></i>
                        Download All Images in batches (ZIP)
                    </button>
                    <div className='clearfix'></div>
                    {metadataProgress.isProcessing && (
                        <div className="mt-3 clearfix">
                            <div className="progress mb-2">
                                <div 
                                    className="progress-bar progress-bar-striped progress-bar-animated bg-info" 
                                    role="progressbar" 
                                    style={{ 
                                        width: `${(metadataProgress.current / metadataProgress.total) * 100}%` 
                                    }}
                                />
                            </div>
                            <div className="text-muted">
                                Generating metadata: {metadataProgress.current} of {metadataProgress.total}
                                <br/>
                                <small>
                                    (Metadata will be split into {Math.ceil(mergedImages.length / METADATA_PER_ZIP)} zip files)
                                </small>
                            </div>
                        </div>
                    )}
                    {downloadProgress.isProcessing && (
                        <div className="mt-3 clearfix">
                            <div className="progress mb-2">
                                <div 
                                    className="progress-bar progress-bar-striped progress-bar-animated" 
                                    role="progressbar" 
                                    style={{ 
                                        width: `${(downloadProgress.current / downloadProgress.total) * 100}%` 
                                    }}
                                />
                            </div>
                            <div className="text-muted">
                                Preparing download: {downloadProgress.current} of {downloadProgress.total}
                                <br/>
                                <small>
                                    (Downloads will be split into {Math.ceil(mergedImages.length / IMAGES_PER_ZIP)} zip files)
                                </small>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {mergedImages.length > 0 && (
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="bg-transparent">
                            <div className="card-body">
                                <div className="d-flex flex-wrap gap-3">
                                    {mergedImages.map((url, index) => (
                                        <div key={index} className="card custom-border pb-0" style={{ width: '220px' }}>
                                            <img 
                                                src={url}
                                                alt={`Combination ${index + 1}`}
                                                className="img-fluid"
                                            />
                                            <div className="card-footer bg-light">
                                                <div className="text-muted text-center mb-2">Combination #{index + 1}</div>
                                                <div className="d-grid gap-2">
                                                    <button 
                                                        className="btn btn-dark btn-sm"
                                                        onClick={() => saveImage(url, index + 1)}
                                                    >
                                                        <i className="bi bi-download me-1"></i>
                                                        Save Image
                                                    </button>
                                                    <button 
                                                        className="btn btn-outline-dark btn-sm"
                                                        onClick={() => saveJson(index + 1)}
                                                    >
                                                        <i className="bi bi-filetype-json me-1"></i>
                                                        Save JSON
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Randomizer;