import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Helpers from '../ext/helpers';
import { BADART_URL } from '../config';
import ConfirmationToast from '../components/ConfirmationToast';
import EditCollection from './EditCollection'; // Import EditCollection component
import empty_bg from '../img/bg.gif';

/**
 * Fetches and updates the collection list for a given wallet address
 * @param {string} starsAddress - The wallet address
 * @param {Function} setCollections - State setter for collections
 */
export const refreshCollectionList = (starsAddress, setCollections) => {
  if (!starsAddress) {
    setCollections([]);
    return;
  }

  fetch(`${BADART_URL}/api.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      walletAdd: starsAddress
    })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Ensure data is always an array
      const collections = Array.isArray(data) ? data : [];
      setCollections(collections);
    })
    .catch(error => {
      console.error('Error fetching collections:', error);
      setCollections([]);
    });
};

/**
 * Displays a list of user collections with edit and delete functionality
 * @param {Object[]} collections - Array of collection objects
 * @param {Function} setMessage - Function to set message
 * @param {Function} setMessageType - Function to set message type
 * @param {string} starsAddress - User's wallet address
 * @param {Function} setCollections - State setter for collections
 * @param {number} ownedCollectionsCount - Maximum number of collections allowed
 */
const CollectionList = ({ 
  collections, 
  setMessage, 
  setMessageType, 
  starsAddress, 
  setCollections,
  ownedCollectionsCount 
}) => {
  // Add validation check
  const isStargazeAddress = starsAddress?.startsWith('stars1');
  
  const [pendingDelete, setPendingDelete] = useState(null);
  const [editingCollection, setEditingCollection] = useState(null); // State for editing collection

  const handleDelete = async (collectionId) => {
    setPendingDelete(collectionId);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`${BADART_URL}/delete_collection.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: pendingDelete,
          walletAdd: starsAddress
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        refreshCollectionList(starsAddress, setCollections);
        Helpers.showMessage(setMessage, setMessageType, data.message, 'success');
      } else {
        Helpers.showMessage(setMessage, setMessageType, data.message, 'error');
      }
    } catch (error) {
      console.error('Error deleting collection:', error);
      Helpers.showMessage(setMessage, setMessageType, 'Error deleting collection', 'error');
    } finally {
      setPendingDelete(null);
    }
  };

  const handleEdit = (collection) => {
    setEditingCollection(collection);
  };

  return (
    <div className="collection-list"> {/* Add collection-list class */}
      {/* Add warning for non-Stargaze addresses */}
      {!isStargazeAddress && (
        <div className="alert alert-warning">
          <i className="bi bi-exclamation-triangle-fill me-2"></i>
          Collections are only available for Stargaze wallet addresses
        </div>
      )}
      {pendingDelete && (
        <ConfirmationToast
          message="Are you sure you want to delete this collection? This action cannot be undone."
          onConfirm={confirmDelete}
          onCancel={() => setPendingDelete(null)}
        />
      )}
      {editingCollection && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button 
              type="button" 
              className="modal-close-btn"
              onClick={() => setEditingCollection(null)}
            >
              ×
            </button>
            <h5 className="modal-title mb-4">Edit Collection</h5>
            <EditCollection 
              collection={editingCollection}
              onClose={() => setEditingCollection(null)}
              refreshCollectionList={() => refreshCollectionList(starsAddress, setCollections)}
              starsAddress={starsAddress}
            />
          </div>
        </div>
      )}
      <div className="row">
        <h4 className="col-10 mb-4">My Collections</h4>
        {collections.length > 0 ? (
          collections.map(item => (
            <div key={item.id} className="col-4 col-md-4 mb-4">
              <div className="card">
                <Link to={`/collection/edit/files/${item.id}`}>
                {item.coverPhoto ? 
                    <img 
                    src={item.coverPhoto ? `${BADART_URL}/${item.coverPhoto}` : ''} 
                    alt={item.name} 
                    className="rounded-top img-fluid mb-3" 
                  />
                  : 
                  <img className="rounded-top my-shadow img-fluid mb-3" src={empty_bg} alt="empty" loading="lazy" />
                  }
 
                </Link>
                <h6 className="mb-3 text-center">{item.name}</h6>
                <div className="btn-group edit-group" role="group">
                  <button 
                    type="button" 
                    onClick={() => handleEdit(item)} 
                    className="btn btn-dark btn-sm col-5"
                  >
                    Edit
                  </button>
                  <Link to={`/collection/edit/files/${item.id}`} className="btn btn-secondary btn-sm col-5">NFT files</Link>
                  <button 
                    type="button" 
                    onClick={() => handleDelete(item.id)} 
                    className="btn btn-danger btn-sm col-3"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p>No data available</p>
          </div>
        )}
      </div>
      <p className='fs-6 mt-3'>Collection limit: <span className="me-1 fs-6 ms-1 fw-bold badge bg-black">{collections.length} / {ownedCollectionsCount}</span></p>
    </div>
  );
};

export default CollectionList;