import { shortenAddress, copyToClipboard, endSession, isWalletAvailable } from './walletUtils';

const getMagicEdenProvider = async (maxAttempts = 5) => {
  for (let i = 0; i < maxAttempts; i++) {
    try {
      if (typeof window === 'undefined') return null;
      
      if (window.magicEden?.solana) {
        return window.magicEden;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error accessing Magic Eden provider:', error);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
  return null;
};

export const connectWallet = async (setStarsAddress, showMessageCallback) => {
  if (!isWalletAvailable('MagicEden')) {
    showMessageCallback?.("Please install Magic Eden wallet", "warning");
    return;
  }
  
  try {
    const provider = await getMagicEdenProvider();
    if (!provider) {
      throw new Error('Magic Eden provider not available');
    }

    let isConnected = false;
    try {
      isConnected = await provider.solana.isConnected;
    } catch (e) {
      console.warn('Error checking connection:', e);
    }

    if (!isConnected) {
      await provider.solana.connect();
      // Wait for connection
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    const publicKey = provider.solana.publicKey;
    if (!publicKey) {
      throw new Error('No public key available');
    }

    const address = publicKey.toString();
    setStarsAddress(address);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('activeWallet', 'MagicEden');
  } catch (error) {
    console.error('Error connecting to Magic Eden:', error);
    showMessageCallback?.("Failed to connect to Magic Eden wallet", "error");
  }
};

export const proveAccountOwnership = async (setStarsAddress, setOwnershipProved, showMessageCallback) => {
  try {
    // Wait longer for provider initialization
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    const provider = await getMagicEdenProvider(10); // Increase max attempts
    if (!provider || !provider.solana) {
      throw new Error('Magic Eden provider not available');
    }

    // Ensure we're connected
    if (!provider.solana.isConnected) {
      await provider.solana.connect();
      // Wait for connection to complete
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    const publicKey = provider.solana.publicKey;
    if (!publicKey) {
      throw new Error('No public key available');
    }

    const address = publicKey.toString();
    const message = "Prove account ownership";
    const encodedMessage = new TextEncoder().encode(message);
    
    try {
      const signature = await provider.solana.signMessage(encodedMessage);
      
      if (signature) {
        setStarsAddress(address);
        setOwnershipProved(true);
        sessionStorage.setItem('starsAddress', address);
        sessionStorage.setItem('ownershipProved', 'true');
        sessionStorage.setItem('activeWallet', 'MagicEden');
        showMessageCallback("Account ownership proved successfully!", "success");
      }
    } catch (signError) {
      console.error('Error signing message:', signError);
      throw new Error('Failed to sign message');
    }
  } catch (error) {
    console.error('Error proving Magic Eden account ownership:', error);
    setOwnershipProved(false);
    sessionStorage.setItem('ownershipProved', 'false');
    showMessageCallback("Error proving Magic Eden account ownership", "error");
  }
};

export { shortenAddress, copyToClipboard, endSession };
