import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as KeplrIntegration from '../wallets/KeplrIntegration';
import * as LeapIntegration from '../wallets/LeapIntegration';
import * as MetaMaskIntegration from '../wallets/MetaMaskIntegration';
import * as MagicEdenIntegration from '../wallets/MagicEdenIntegration';
import * as SolflareIntegration from '../wallets/SolflareIntegration';
import { toggleButtonsVisibility } from '../ext/helpers';
import { showMessage, endSession, isWalletAvailable } from '../wallets/walletUtils';

const Header = ({ 
  ownershipProved, 
  starsAddress, 
  setStarsAddress, 
  setOwnershipProved, 
  setMessage, 
  setMessageType,
  setTotalCount,
  showWalletModal, // Add this prop
  setShowWalletModal // Add this prop
}) => {
  const navigate = useNavigate();
  const activeWallet = sessionStorage.getItem('activeWallet');
  const [darkMode, setDarkMode] = React.useState(localStorage.getItem('darkMode') === 'true');
  const [walletAvailability, setWalletAvailability] = React.useState({});
  // Remove the local state since it's now passed as prop
  // const [showWalletModal, setShowWalletModal] = React.useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  // Add effect to check wallet availability when modal opens
  useEffect(() => {
    if (showWalletModal) {
      const availability = {
        Keplr: isWalletAvailable('Keplr'),
        Leap: isWalletAvailable('Leap'),
        MetaMask: isWalletAvailable('MetaMask'),
        'Magic Eden': isWalletAvailable('MagicEden'),
        Solflare: isWalletAvailable('Solflare')
      };
      setWalletAvailability(availability);
    }
  }, [showWalletModal]);

  // Use the correct wallet integration based on active wallet type
  const getActiveWalletIntegration = () => {
    return activeWallet === 'Leap' ? LeapIntegration : KeplrIntegration;
  };

  const handleEndSession = () => {
    sessionStorage.removeItem('activeWallet'); // Clear active wallet
    endSession(
      setStarsAddress, 
      setOwnershipProved, 
      null,
      (msg, type) => showMessage(setMessage, setMessageType, msg, type),
      navigate
    );
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    // Dispatch custom event for dark mode change
    window.dispatchEvent(new CustomEvent('darkModeChange', { detail: newDarkMode }));
  };

  return (
    <div className="row mb-3">
      <div className="col-8 col-md-5 rounded-top ms-1 pt-3">
        <h2 className="logo" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}><span className='text-secondary'>bad</span>art <sup>APP</sup></h2>
      </div>
      
      <div className="col p-0">

        <div className="bg-light float-end pb-2">
          
          {ownershipProved && (
            <div className="d-inline ms-1 font-small">
              <small className='me-2 ms-3'>
                {starsAddress ? getActiveWalletIntegration().shortenAddress(starsAddress) : 'Not connected'}
              </small>
              <button className='btn p-0' onClick={() => getActiveWalletIntegration().copyToClipboard(starsAddress)}>
                <i className="bi bi-copy text-dark me-3" id='copy_add'></i>
              </button>
            </div>
          )}

          {!ownershipProved ? (
            <>
              <button
                className="btn bg-dark ms-2 loginBtnNot text-white d-inline"
                onClick={() => setShowWalletModal(true)}>
                Connect
              </button>

              {/* Wallet Selection Modal */}
              {showWalletModal && (
                <div className="modal-overlay" onClick={() => setShowWalletModal(false)}>
                  <div className="wallet-modal" onClick={e => e.stopPropagation()}>
                    <div className="wallet-modal-header">
                      <p className='d-block ms-3 mb-0 mt-2 h5 py-3'>Connect your wallet</p>
                      <button className="modal-close-btn" onClick={() => setShowWalletModal(false)}>
                        <i className="bi bi-x text-dark"></i>
                      </button>
                    </div>
                    <div className="wallet-modal-body">
                      {[
                        {
                          name: 'Keplr',
                          icon: 'img/keplr-icon.png',
                          integration: KeplrIntegration
                        },
                        {
                          name: 'Leap',
                          icon: 'img/leap-icon.png',
                          integration: LeapIntegration
                        },
                        {
                          name: 'MetaMask',
                          icon: 'img/metamask-icon.png',
                          integration: MetaMaskIntegration
                        },
                        {
                          name: 'Magic Eden',
                          icon: 'img/magiceden-icon.png',
                          integration: MagicEdenIntegration
                        },
                        {
                          name: 'Solflare',
                          icon: 'img/solflare-icon.jpeg',
                          integration: SolflareIntegration
                        }
                      ].map((wallet, index) => (
                        <button 
                          key={wallet.name}
                          className={`wallet-option ${!walletAvailability[wallet.name] ? 'wallet-unavailable' : ''}`}
                          onClick={() => {
                            if (walletAvailability[wallet.name]) {
                              wallet.integration.proveAccountOwnership(
                                setStarsAddress, 
                                setOwnershipProved,
                                (msg, type) => showMessage(setMessage, setMessageType, msg, type)
                              );
                              setShowWalletModal(false);
                            }
                          }}
                        >
                          <img src={wallet.icon} alt={wallet.name} />
                          <span className='text-dark'>{wallet.name}</span>
                          <span className="wallet-status ms-auto">
                            {walletAvailability[wallet.name] ? (
                              <span className='text-success fs-6'><i className="bi bi-check-circle-fill text-success me-2"></i>installed</span>
                            ) : (
                              <></>
                            )}
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="btn-group">
              <button 
                className="btn btn-secondary logoutBtn text-white d-inline" 
                onClick={handleEndSession}>
                <i className="bi bi-box-arrow-right"></i>
              </button>
            </div>
          )}
        </div>
        <button
            className="theme-toggle nav-link d-inline me-2 float-end"
            onClick={toggleDarkMode}
            aria-label="Toggle dark mode"
          >
            {darkMode ? (
              <i className="bi bi-sun-fill"></i>
            ) : (
              <i className="bi bi-moon-fill"></i>
            )}
          </button>
        {ownershipProved && (
        <a href='#/tickets' className='float-end pt-2'>
          <i className="fs-5 bi bi-bell-fill text-dark"></i>
          {/* <sup className="fs-9 badge rounded-pill badge-notification bg-danger">1</sup> */}
        </a>
        )}
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default Header;