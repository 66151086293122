/**
 * Shortens a wallet address for display
 */
export const shortenAddress = (address) => {
  if (address?.length > 8) {
    return `${address.slice(0, 9)}...${address.slice(-3)}`;
  }
  return address;
};

/**
 * Copies text to clipboard and shows success indicator
 */
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      const copyAddElement = document.getElementById('copy_add');
      if (copyAddElement) {
        copyAddElement.classList.add('text-success');
      }
    })
    .catch(err => console.error('Error copying text to clipboard:', err));
};

/**
 * Shows a temporary message to the user
 */
export const showMessage = (setMessage, setMessageType, msg, type) => {
  setMessage(msg);
  setMessageType(type);
  setTimeout(() => {
    setMessage(null);
    setMessageType(null);
  }, 5000);
};

/**
 * Ends the current wallet session and cleans up storage
 */
export const endSession = (setStarsAddress, setOwnershipProved, setButtonText, showMessage, navigate) => {
  setStarsAddress(null);
  setOwnershipProved(false);
  sessionStorage.removeItem('starsAddress');
  sessionStorage.removeItem('ownershipProved');
  sessionStorage.removeItem('keplrSignature');
  sessionStorage.removeItem('activeWallet'); // Add this line
  if (typeof setButtonText === 'function') {
    setButtonText('Connect wallet');
  }
  showMessage("Session ended successfully!", "success");
  if (navigate) {
    navigate('/');
  }
};

/**
 * Base function for proving account ownership
 */
export const baseProveAccountOwnership = async (
  walletWindow,
  chainId,
  setStarsAddress,
  setOwnershipProved,
  showMessageCallback,
  getOfflineSigner,
  walletType // Add walletType parameter
) => {
  if (!walletWindow) {
    showMessageCallback(`Please install ${walletType} extension`, "warning");
    return;
  }

  try {
    await walletWindow.enable(chainId);
    const offlineSigner = getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    const address = accounts[0].address;

    const message = "Prove account ownership";
    // Use the specific wallet's signArbitrary
    await walletWindow.signArbitrary(chainId, address, message);
    
    setStarsAddress(address);
    setOwnershipProved(true);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('ownershipProved', 'true');
    sessionStorage.setItem('activeWallet', walletType); // Store active wallet type
    showMessageCallback("Account ownership proved successfully!", "success");
  } catch (error) {
    console.error(`Error proving ${walletType} account ownership:`, error);
    setOwnershipProved(false);
    sessionStorage.setItem('ownershipProved', 'false');
    showMessageCallback(`Error proving ${walletType} account ownership`, "error");
  }
};

/**
 * Base function for connecting wallet
 */
export const baseConnectWallet = async (
  walletWindow, 
  chainId, 
  setStarsAddress, 
  getOfflineSigner,
  walletType,
  showMessageCallback
) => {
  if (!walletWindow) {
    if (showMessageCallback) {
      showMessageCallback(`Please install ${walletType} extension`, "warning");
    }
    return;
  }

  try {
    let address;
    
    switch(walletType) {
      case 'MagicEden':
        await walletWindow.solana.connect();
        const publicKey = await walletWindow.solana.publicKey;
        address = publicKey.toString();
        break;

      case 'Solflare':
        await walletWindow.connect();
        address = walletWindow.publicKey.toString();
        break;

      case 'MetaMask':
        await walletWindow.request({ method: 'eth_requestAccounts' });
        const accounts = await walletWindow.request({ method: 'eth_accounts' });
        address = accounts[0];
        break;

      default: // Keplr and Leap wallets
        await walletWindow.enable(chainId);
        const offlineSigner = getOfflineSigner(chainId);
        const cosmosAccounts = await offlineSigner.getAccounts();
        address = cosmosAccounts[0].address;
        break;
    }

    setStarsAddress(address);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('activeWallet', walletType);
  } catch (error) {
    console.error(`Error connecting to ${walletType}:`, error);
    if (showMessageCallback) {
      showMessageCallback(`Failed to connect to ${walletType}`, "error");
    }
  }
};

/**
 * Check if specific wallet is available
 */
export const isWalletAvailable = (walletType) => {
  try {
    if (walletType === 'MagicEden') {
      // Only check for base object, not .solana
      return typeof window !== 'undefined' && !!window.magicEden;
    } else if (walletType === 'Leap') {
      return typeof window.leap !== 'undefined';
    } else if (walletType === 'Keplr') {
      return typeof window.keplr !== 'undefined';
    } else if (walletType === 'MetaMask') {
      return typeof window.ethereum !== 'undefined';
    } else if (walletType === 'Solflare') {
      return typeof window.solflare !== 'undefined';
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * Wait for wallet to be available
 */
export const waitForWallet = async (walletType, maxAttempts = 10) => {
  // First check if the specified wallet is already available
  if (isWalletAvailable(walletType)) {
    return true;
  }

  // If Leap is not available but Keplr is requested and available, return true
  if (walletType === 'Keplr' && isWalletAvailable('Keplr')) {
    return true;
  }

  // If neither is immediately available, wait and check again
  for (let i = 0; i < maxAttempts; i++) {
    await new Promise(resolve => setTimeout(resolve, 500));
    
    if (isWalletAvailable(walletType)) {
      return true;
    }
    
    // If waiting for Keplr and it becomes available, return true
    if (walletType === 'Keplr' && isWalletAvailable('Keplr')) {
      return true;
    }
  }
  
  return false;
};

/**
 * Initialize active wallet from session storage
 */
export const initializeWallet = async (starsAddress, setStarsAddress) => {
  const activeWallet = sessionStorage.getItem('activeWallet');
  if (starsAddress && activeWallet) {
    // Add retry mechanism for MagicEden
    const maxRetries = 5;
    let attempts = 0;

    while (attempts < maxRetries) {
      const isAvailable = await waitForWallet(activeWallet);
      if (isAvailable) break;
      await new Promise(resolve => setTimeout(resolve, 1000));
      attempts++;
    }

    let walletWindow;
    switch(activeWallet) {
      case 'Leap':
        walletWindow = window.leap;
        break;
      case 'Keplr':
        walletWindow = window.keplr;
        break;
      case 'Solflare':
        // Check if already connected before initializing
        if (window.solflare?.isConnected) {
          walletWindow = window.solflare;
        } else {
          return; // Skip reconnection if not already connected
        }
        break;
      case 'MagicEden':
        // Wait for provider to be fully initialized
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (window.magicEden?.solana) {
          try {
            const isConnected = await window.magicEden.solana.isConnected;
            if (isConnected) {
              walletWindow = window.magicEden;
            } else {
              return; // Skip if not connected
            }
          } catch (error) {
            console.warn('Error checking MagicEden connection:', error);
            return;
          }
        } else {
          return;
        }
        break;
      case 'MetaMask':
        walletWindow = window.ethereum;
        break;
      default:
        console.warn('Unknown wallet type');
        return;
    }

    try {
      const chainId = activeWallet === 'Solflare' || activeWallet === 'MagicEden' ? 
        'solana' : 
        activeWallet === 'MetaMask' ? 
          'ethereum' : 
          'stargaze-1';

      await baseConnectWallet(
        walletWindow,
        chainId,
        setStarsAddress,
        (chainId) => activeWallet === 'Keplr' || activeWallet === 'Leap' ? 
          walletWindow.getOfflineSigner(chainId) : 
          walletWindow,
        activeWallet
      );
    } catch (error) {
      console.error('Error reconnecting wallet:', error);
      clearWalletSession();
      setStarsAddress(null);
    }
  }
};

/**
 * Clear all wallet-related session storage items
 */
export const clearWalletSession = () => {
  sessionStorage.removeItem('starsAddress');
  sessionStorage.removeItem('ownershipProved');
  sessionStorage.removeItem('activeWallet');
  sessionStorage.removeItem('keplrSignature');
};

/**
 * Handle wallet changes and reconnection
 */
export const setupWalletChangeHandlers = (setStarsAddress, setOwnershipProved, navigate, callback) => {
  const handleWalletChange = async () => {
    const addr = sessionStorage.getItem('starsAddress');
    const activeWallet = sessionStorage.getItem('activeWallet');

    if (!addr || !activeWallet) {
      endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
      return;
    }

    let walletWindow;
    switch(activeWallet) {
      case 'Leap':
        walletWindow = window.leap;
        break;
      case 'Keplr':
        walletWindow = window.keplr;
        break;
      case 'Solflare':
        walletWindow = window.solflare;
        break;
      case 'MagicEden':
        walletWindow = window.magicEden;
        break;
      case 'MetaMask':
        walletWindow = window.ethereum;
        break;
      default:
        endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
        return;
    }

    if (!walletWindow) {
      endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
      return;
    }

    try {
      if (activeWallet === 'Solflare') {
        // Check connection state without triggering connect popup
        const isConnected = await window.solflare?.isConnected;
        const currentAddress = isConnected ? await window.solflare.publicKey?.toString() : null;
        
        if (!isConnected || currentAddress !== addr) {
          endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
        } else {
          setStarsAddress(addr);
          if (callback) await callback();
        }
      } else if (activeWallet === 'MagicEden') {
        // Handle Solana wallets differently
        const currentAddress = await walletWindow.publicKey?.toString();
        if (currentAddress !== addr) {
          endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
        } else {
          setStarsAddress(addr);
          if (callback) await callback();
        }
      } else if (activeWallet === 'MetaMask') {
        // Handle MetaMask
        const accounts = await walletWindow.request({ method: 'eth_accounts' });
        if (accounts[0]?.toLowerCase() !== addr?.toLowerCase()) {
          endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
        } else {
          setStarsAddress(addr);
          if (callback) await callback();
        }
      } else {
        // Handle Cosmos wallets (Keplr/Leap)
        await walletWindow.enable("stargaze-1");
        const offlineSigner = walletWindow.getOfflineSigner("stargaze-1");
        const accounts = await offlineSigner.getAccounts();
        
        if (accounts[0].address !== addr) {
          endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
        } else {
          setStarsAddress(addr);
          if (callback) await callback();
        }
      }
    } catch (error) {
      console.error('Error checking wallet connection:', error);
      endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
    }
  };

  // Set up wallet-specific change handlers
  const activeWallet = sessionStorage.getItem('activeWallet');
  if (activeWallet === 'Keplr') {
    window.addEventListener("keplr_keystorechange", handleWalletChange);
    return () => window.removeEventListener("keplr_keystorechange", handleWalletChange);
  } else if (activeWallet === 'Leap') {
    window.addEventListener("leap_keystorechange", handleWalletChange);
    return () => window.removeEventListener("leap_keystorechange", handleWalletChange);
  } else if (activeWallet === 'Solflare') {
    // Solflare specific events if any
    return () => {};
  } else if (activeWallet === 'MetaMask') {
    window.ethereum?.on('accountsChanged', handleWalletChange);
    return () => window.ethereum?.removeListener('accountsChanged', handleWalletChange);
  }

  return () => {};
};
