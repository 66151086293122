import { shortenAddress, copyToClipboard, baseConnectWallet, baseProveAccountOwnership, endSession, isWalletAvailable } from './walletUtils';

export const connectWallet = async (setStarsAddress, showMessageCallback) => {
  if (!isWalletAvailable('MetaMask')) {
    console.warn('MetaMask not available');
    return;
  }
  
  return baseConnectWallet(
    window.ethereum, 
    "ethereum", 
    setStarsAddress,
    () => window.ethereum,
    'MetaMask',
    showMessageCallback
  );
};

export const proveAccountOwnership = async (setStarsAddress, setOwnershipProved, showMessageCallback) => {
  if (!window.ethereum) {
    showMessageCallback("Please install MetaMask", "warning");
    return;
  }

  try {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const address = accounts[0];

    const message = "Prove account ownership";
    const signature = await window.ethereum.request({
      method: 'personal_sign',
      params: [message, address],
    });
    
    setStarsAddress(address);
    setOwnershipProved(true);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('ownershipProved', 'true');
    sessionStorage.setItem('activeWallet', 'MetaMask');
    showMessageCallback("Account ownership proved successfully!", "success");
  } catch (error) {
    console.error('Error proving MetaMask account ownership:', error);
    setOwnershipProved(false);
    sessionStorage.setItem('ownershipProved', 'false');
    showMessageCallback("Error proving MetaMask account ownership", "error");
  }
};

export { shortenAddress, copyToClipboard, endSession };
