import React, { useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import JSZip from 'jszip';
import axios from 'axios';
import { BADART_URL } from '../config';
import Toast from '../components/Toast';
import ConfirmationToast from '../components/ConfirmationToast';

const Renameizer = () => {
  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(1);
  const BATCH_SIZE = 100;
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [hoveredFile, setHoveredFile] = useState(null);
  const [isVerifyingCredits, setIsVerifyingCredits] = useState(true);
  const [creditsLimit, setCreditsLimit] = useState(null);
  const [starsAddress, setStarsAddress] = useState(sessionStorage.getItem('starsAddress') || null);
  const [completedBatches, setCompletedBatches] = useState(0);

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [toast, setToast] = useState({ message: '', type: '' });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (creditsLimit !== null) {
      const remainingCredits = creditsLimit - files.length;
      if (remainingCredits <= 0) {
        setToast({
          message: `Credit limit reached. Cannot add more files.`,
          type: 'error'
        });
        return;
      }
      const filesToAdd = droppedFiles.slice(0, remainingCredits);
      setFiles(prevFiles => [...prevFiles, ...filesToAdd]);
      if (droppedFiles.length > remainingCredits) {
        setToast({
          message: `Added ${filesToAdd.length} files. Skipped ${droppedFiles.length - filesToAdd.length} files due to credit limit.`,
          type: 'warning'
        });
      } else {
        setToast({
          message: `Successfully added ${filesToAdd.length} files`,
          type: 'success'
        });
      }
    } else {
      setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
      setToast({
        message: `Successfully added ${droppedFiles.length} files`,
        type: 'success'
      });
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    if (creditsLimit !== null) {
      const remainingCredits = creditsLimit - files.length;
      if (remainingCredits <= 0) {
        setToast({
          message: `Credit limit reached. Cannot add more files.`,
          type: 'error'
        });
        return;
      }
      const filesToAdd = newFiles.slice(0, remainingCredits);
      setFiles(prevFiles => [...prevFiles, ...filesToAdd]);
      if (newFiles.length > remainingCredits) {
        setToast({
          message: `Added ${filesToAdd.length} files. Skipped ${newFiles.length - filesToAdd.length} files due to credit limit.`,
          type: 'warning'
        });
      } else {
        setToast({
          message: `Successfully added ${filesToAdd.length} files`,
          type: 'success'
        });
      }
    } else {
      setFiles(prevFiles => [...prevFiles, ...newFiles]);
      setToast({
        message: `Successfully added ${newFiles.length} files`,
        type: 'success'
      });
    }
  };

  const handleDeleteFile = (index) => {
    const fileToDelete = files[index];
    setShowConfirmation({
      message: `Are you sure you want to delete "${fileToDelete.name}"?`,
      onConfirm: () => {
        setFiles(files.filter((_, i) => i !== index));
        setShowConfirmation(false);
        setToast({
          message: 'File successfully deleted',
          type: 'success'
        });
      }
    });
  };

  const handleClearFiles = () => {
    if (files.length === 0) return;
    
    setShowConfirmation({
      message: `Are you sure you want to clear all ${files.length} files?`,
      onConfirm: () => {
        setFiles([]);
        setShowConfirmation(false);
        setToast({
          message: 'All files cleared successfully',
          type: 'success'
        });
      }
    });
  };

  const processBatch = async (batchFiles, batchStartIndex) => {
    const zip = new JSZip();
    let processed = 0;

    const filePromises = batchFiles.map((file, index) => {
      return new Promise((resolve, reject) => {
        const extension = file.name.split('.').pop();
        const newName = `${batchStartIndex + index + 1}.${extension}`;
        
        const reader = new FileReader();
        reader.onload = (e) => {
          zip.file(newName, e.target.result);
          processed++;
          setProgress((processed / batchFiles.length) * 100);
          resolve();
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    });

    await Promise.all(filePromises);
    return zip;
  };

  const handleRename = async () => {
    try {
      setIsProcessing(true);
      setProgress(0);
      setCompletedBatches(0);

      const totalBatches = Math.ceil(files.length / BATCH_SIZE);
      
      for (let i = 0; i < files.length; i += BATCH_SIZE) {
        setCurrentBatch(Math.floor(i / BATCH_SIZE) + 1);
        const batchFiles = files.slice(i, i + BATCH_SIZE);
        
        const zip = await processBatch(batchFiles, i);
        const content = await zip.generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: { level: 9 }
        });

        // Download the batch
        const downloadUrl = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `renamed_files_${i + 1}_to_${i + batchFiles.length}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
        
        setCompletedBatches(prev => prev + 1);
      }
      
      setToast({
        message: 'All files have been successfully renamed and downloaded',
        type: 'success'
      });
    } catch (error) {
      console.error('Error creating zip file:', error);
      setToast({
        message: 'Error processing files. Please try again.',
        type: 'error'
      });
    } finally {
      setIsProcessing(false);
      setProgress(0);
      setCurrentBatch(1);
      setCompletedBatches(0);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(files);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFiles(items);
  };

  const generateSafeId = (file, index) => {
    return `file-${index}-${btoa(file.name).replace(/[^a-zA-Z0-9]/g, '')}`;
  };

  const generateThumbnail = (file) => {
    if (!file.type.startsWith('image/')) {
      return null;
    }
    return URL.createObjectURL(file);
  };

  const getPaginatedFiles = () => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return files.slice(start, end);
  };

  const getTotalPages = () => {
    return Math.ceil(files.length / pageSize);
  };

  const renderPagination = () => {
    const totalPages = getTotalPages();
    const maxVisiblePages = 5;
    let pages = [];

    if (totalPages <= maxVisiblePages) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, '...', totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
      }
    }

    return (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex align-items-center">
          <span className="me-2">Items per page:</span>
          <select 
            className="form-select form-select-sm w-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            {[10, 20, 50, 100].map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        <nav aria-label="File list pagination">
          <ul className="pagination mb-0 rounded bg-light">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button 
                className="page-link my-size-2 bg-transparent" 
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              >
                Previous
              </button>
            </li>
            
            {pages.map((page, index) => (
              <li 
                key={index} 
                className={`page-item ${currentPage === page ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}
              >
                <button 
                  className="page-link"
                  onClick={() => typeof page === 'number' && setCurrentPage(page)}
                >
                  {page}
                </button>
              </li>
            ))}

            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button 
                className="page-link" 
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  // Add truncate function
  const truncateFileName = (fileName, maxLength = 50) => {
    if (fileName.length <= maxLength) return fileName;
    
    const extension = fileName.split('.').pop();
    const nameWithoutExt = fileName.slice(0, -(extension.length + 1));
    
    const truncatedLength = maxLength - extension.length - 3; // 3 for '...'
    const truncatedName = nameWithoutExt.slice(0, truncatedLength) + '...';
    
    return `${truncatedName}.${extension}`;
  };

  useEffect(() => {
    return () => {
      // Cleanup any created object URLs when component unmounts
      files.forEach(file => {
        if (file.thumbnailUrl) {
          URL.revokeObjectURL(file.thumbnailUrl);
        }
      });
    };
  }, [files]);

  useEffect(() => {
    const verifyCredits = async () => {
      if (!starsAddress) {
        setIsVerifyingCredits(false);
        setCreditsLimit(100); // Set default limit of 100 for non-connected users
        return;
      }

      try {
        const response = await axios.get(`${BADART_URL}/fetch.php?stars=${starsAddress}`);
        const { credits } = response.data;
        setCreditsLimit(Math.max(credits, 100)); // Use whichever is higher: credits or 100
      } catch (error) {
        console.error('Error verifying credits:', error);
        setCreditsLimit(100); // Fallback to 100 if verification fails
      } finally {
        setIsVerifyingCredits(false);
      }
    };

    verifyCredits();
  }, [starsAddress]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {isVerifyingCredits ? (
            <div className="text-center my-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-2">Verifying credits...</p>
            </div>
          ) : (
            <>
              {creditsLimit !== null && (
                <div className="alert alert-info mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="bi bi-info-circle me-2"></i>
                      {starsAddress ? (
                        `You can rename up to ${creditsLimit} files based on your credits.`
                      ) : (
                        'Free version allows renaming up to 100 files. Connect wallet for more.'
                      )}
                      {files.length > 0 && (
                        <span className="ms-2">
                          Currently using: <strong>{files.length}</strong> / {creditsLimit}
                        </span>
                      )}
                    </div>
                    {files.length > BATCH_SIZE && (
                      <small className="text-muted">
                        Files will be processed in {Math.ceil(files.length / BATCH_SIZE)} batches of {BATCH_SIZE}
                      </small>
                    )}
                  </div>
                </div>
              )}

              {files.length === 0 ? (
                // Centered upload form when no files
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <p className='fs-6 pt-3 text-center'>You can rename up to: <span className="me-1 fs-6 ms-1 fw-bold badge bg-primary">{creditsLimit}</span> NFT files.</p>
                    <div className="card mb-4 bg-transparent custom-border">
                      <div className="card-body">
                        <div
                          className={`upload-area p-4 text-center bg-transparent custom-border rounded mb-4 ${
                            isDragging ? 'border-primary bg-light' : 'border-dashed'
                          }`}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          onClick={() => fileInputRef.current?.click()}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="bi bi-cloud-upload fs-1 text-dark mb-3 d-block"></i>
                          <p className="mb-0">
                            Drag & drop files here or{' '}
                            <span className="text-primary">browse files</span>
                          </p>
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="d-none"
                            multiple
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // Existing two-column layout when files are present
                <div className="row">
                  {/* File List Column */}
                  <div className="col-8">
                    <label className="form-label">Selected Files: (drag to reorder)</label>
                    <div className="card bg-transparent custom-border">
                      <div className="card-body">
                        {files.length > 0 ? (
                          <div className="mb-3">
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="fileList" type="fileList">
                                {(provided, snapshot) => (
                                  <div 
                                    className={`list-group ${snapshot.isDraggingOver ? 'bg-light' : ''}`}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {getPaginatedFiles().map((file, index) => {
                                      const safeId = generateSafeId(file, (currentPage - 1) * pageSize + index);
                                      const isImage = file.type.startsWith('image/');
                                      const truncatedName = truncateFileName(file.name);
                                      
                                      return (
                                        <Draggable 
                                          key={safeId}
                                          draggableId={safeId}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center position-relative ${
                                                snapshot.isDragging ? 'bg-light border-primary' : ''
                                              }`}
                                            >
                                              <div className="d-flex align-items-center">
                                                <div 
                                                  {...provided.dragHandleProps}
                                                  className="me-2 text-secondary"
                                                >
                                                  <i className="bi bi-grip-vertical"></i>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  {isImage ? (
                                                    <div 
                                                      className="position-relative"
                                                      onMouseEnter={() => setHoveredFile(file)}
                                                      onMouseLeave={() => setHoveredFile(null)}
                                                      title={file.name}
                                                    >
                                                      <i className="bi bi-image me-2"></i>
                                                      {hoveredFile === file && (
                                                        <div 
                                                          className="position-absolute thumbnail-preview"
                                                          style={{
                                                            bottom: '100%',
                                                            left: '0',
                                                            zIndex: 1000,
                                                            background: 'white',
                                                            padding: '4px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: '4px',
                                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                                          }}
                                                        >
                                                          <img 
                                                            src={generateThumbnail(file)}
                                                            alt="Preview"
                                                            style={{
                                                              maxWidth: '150px',
                                                              maxHeight: '150px',
                                                              objectFit: 'contain'
                                                            }}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <i className="bi bi-file-earmark me-2"></i>
                                                  )}
                                                  <span title={file.name}>{truncatedName}</span>
                                                  <small className="text-muted ms-2">
                                                    ({(file.size / 1024).toFixed(1)} KB)
                                                  </small>
                                                </div>
                                              </div>
                                              <button
                                                className="btn btn-sm btn-outline-danger"
                                                onClick={() => handleDeleteFile(index)}
                                                title="Remove file"
                                              >
                                                <i className="bi bi-trash"></i>
                                              </button>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                            <small className="text-muted mt-2 d-block">
                              Total files: {files.length}
                            </small>
                            {renderPagination()}
                          </div>
                        ) : (
                          <div className="text-center text-muted p-4">
                            <i className="bi bi-info-circle fs-1 mb-4"></i>
                            <p>No files selected.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Controls Column */}
                  <div className="col-4">
                    <p className='fs-6 pt-3'>You can rename up to: <span className="me-1 fs-6 ms-1 fw-bold badge bg-primary">{creditsLimit}</span> NFT files.</p>

                    <div className="card mb-4 bg-transparent custom-border">
                      <div className="card-body">
                        <div
                          className={`upload-area p-4 text-center  bg-transparent custom-border rounded mb-4 ${
                            isDragging ? 'border-primary bg-light' : 'border-dashed'
                          }`}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          onClick={() => fileInputRef.current?.click()}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="bi bi-cloud-upload fs-1 text-dark mb-3 d-block"></i>
                          <p className="mb-0">
                            Drag & drop files here or{' '}
                            <span className="text-primary">browse files</span>
                          </p>
                          <small className="text-muted d-block mt-2">
                            {files.length} files selected
                          </small>
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="d-none"
                            multiple
                            onChange={handleFileChange}
                          />
                        </div>

                        <div className="d-grid gap-2">
                          <button
                            className="btn btn-dark d-flex align-items-center justify-content-center"
                            onClick={handleRename}
                            disabled={!files.length || isProcessing}
                          >
                            {isProcessing ? (
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            ) : (
                              <i className="bi bi-arrow-clockwise me-2"></i>
                            )}
                            {isProcessing ? 'Processing...' : `Rename (${files.length}) files`}
                          </button>
                          <button
                            className="btn btn-outline-danger"
                            onClick={handleClearFiles}
                            disabled={!files.length || isProcessing}
                          >
                            <i className="bi bi-trash me-2"></i>
                            Clear all files
                          </button>
                        </div>
                      </div>
                    </div>

                    {isProcessing && (
                      <div className="card">
                        <div className="card-body">
                          <div className="alert alert-warning mb-3">
                            <i className="bi bi-exclamation-triangle-fill me-2"></i>
                            <strong>Important:</strong> Please do not refresh or close this page.
                            <div className="mt-2 small">
                              <i className="bi bi-info-circle me-2"></i>
                              Batches completed: {completedBatches} of {Math.ceil(files.length / BATCH_SIZE)}
                            </div>
                          </div>
                          <div className="progress">
                            <div 
                              className="progress-bar progress-bar-striped progress-bar-animated" 
                              role="progressbar" 
                              style={{ width: `${progress}%` }}
                              aria-valuenow={progress} 
                              aria-valuemin="0" 
                              aria-valuemax="100"
                            />
                          </div>
                          <small className="text-muted d-block text-center mt-2">
                            Processing: {Math.round(progress)}%
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      
      {/* Keep existing toast components */}
      {toast.message && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ message: '', type: '' })}
        />
      )}

      {showConfirmation && (
        <ConfirmationToast
          message={showConfirmation.message}
          onConfirm={showConfirmation.onConfirm}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

// Add these styles to your CSS
const styles = `
.border-dashed {
  border-style: dashed !important;
  border-width: 2px !important;
}

.upload-area:hover {
  border-color: var(--bs-primary) !important;
  background-color: var(--bs-light);
}

.thumbnail-preview {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.thumbnail-preview:hover {
  opacity: 1;
}

.thumbnail-preview img {
  display: block;
}
`;

export default Renameizer;
