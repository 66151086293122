import React from 'react';

const XmasPage = () => {
  const participants = [
  {
    stars: 'stars1ap6...5f6a',
    twitter: '@nftdreamerstar',
    joined: '2024-12-13',
    prize: 'https://www.stargaze.zone/m/bat-kids/190',
    result: 'https://simpliers.com/en/giveaway/result/TAOQOV'
  },
  {
    stars: 'stars1fu...7stf',
    twitter: '@EveNasle',
    joined: '2024-12-14',
    prize: 'https://www.stargaze.zone/m/bat-kids/164',
    result: 'https://app.thexpicker.com/draw/1734254216707'
  },
  {
    stars: 'stars1fu...7stf',
    twitter: '@EveNasle',
    joined: '2024-12-15',
    prize: 'https://www.stargaze.zone/m/bad-morning/166',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars14e...pclk',
    twitter: '@airdropmikenfts',
    joined: '2024-12-16',
    prize: 'https://www.stargaze.zone/m/bad-morning/883',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars1c7...gwk4',
    twitter: '@Frogstar83',
    joined: '2024-12-17',
    prize: 'https://www.stargaze.zone/m/lazy-sloth/1932',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars1dk...8qs0',
    twitter: '@MlleAC1',
    joined: '2024-12-18',
    prize: 'https://www.stargaze.zone/m/bat-kids/176',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars1ds2...29xl',
    twitter: '@Kdzefre',
    joined: '2024-12-19',
    prize: 'https://www.stargaze.zone/m/lazy-sloth/1324',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars14e...pclk',
    twitter: '@airdropmikenfts',
    joined: '2024-12-20',
    prize: 'https://www.stargaze.zone/m/bad-morning/883',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars1c3a...u55x',
    twitter: '@status_patch',
    joined: '2024-12-21',
    prize: 'https://www.stargaze.zone/m/bad-morning/450',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars1zge...dlz7',
    twitter: '@DwD1987',
    joined: '2024-12-22',
    prize: 'https://www.stargaze.zone/m/lazy-sloth/53',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  },
  {
    stars: 'stars1xmv9...n7v',
    twitter: '@svhrebniev',
    joined: '2024-12-23',
    prize: 'https://www.stargaze.zone/m/lazy-sloth/649',
    result: 'https://app.thexpicker.com/draw/1734341226200'
  }
];

  return (
    <div className="container-fluid xmas-table">
      <div className="row">
        <div className="col-12">
          <div className="card bg-transparent custom-border">
            <div className="card-body">
              <h2 className="mb-4 text-dark">
                <i className="bi bi-snow2 text-primary me-2"></i>
                Christmas Event Winners
              </h2>
              <div className="table-responsive">
                <table className="table table-hover xmas-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Stargaze Address</th>
                      <th scope="col">Twitter</th>
                      <th scope="col">Date</th>
                      <th scope="col">Prize</th>
                      {/* <th scope="col">Giveaway Result</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {participants.map((participant, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <small>{participant.stars}</small>
                        </td>
                        <td>
                          <a href={`https://twitter.com/${participant.twitter.substring(1)}`} 
                             target="_blank" 
                             rel="noopener noreferrer">
                            {participant.twitter}
                          </a>
                        </td>
                        <td>{participant.joined}</td>
                        <td>
                          <a href={participant.prize} target="_blank" rel="noopener noreferrer">
                            Prize Link
                          </a>
                        </td>
                        {/* <td>
                          <a href={participant.result} target="_blank" rel="noopener noreferrer">
                            Result Link
                          </a>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XmasPage;
