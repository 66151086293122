import React, { useEffect, useState } from 'react';
import TokenDisplay from './TokenDisplay';
import ApplyForm from './apply/applyForm';  // Add this import
import collectionAddresses from './collections/collectionAddresses';
import forArtistsImage from './img/for-artists.jpg';
import forArtistsImageBlack from './img/for-artists-black.jpg';
import forCollectionsImage from './img/for-collections.jpg';
import forCollectionsImageBlack from './img/for-collections-black.jpg';
import forRetailImage from './img/for-retail.jpg';
import forRetailImageBlack from './img/for-retail-black.jpg';
import badArtLogo from './img/logo.png';
import badArtLogoBlack from './img/logo-black.png';
import empty_bg from './img/bg.gif';

const HomePage = ({ setShowWalletModal }) => {
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

  // Add ref for ApplyForm section
  const applyFormRef = React.useRef(null);

  // Add scroll handler
  const scrollToApplyForm = () => {
    applyFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleGetStarted = () => {
    setShowWalletModal(true);
  };

  useEffect(() => {
    // Set initial dark mode
    setIsDarkMode(localStorage.getItem('darkMode') === 'true');

    // Listen for dark mode changes
    const handleDarkModeChange = (e) => {
      setIsDarkMode(e.detail);
    };

    window.addEventListener('darkModeChange', handleDarkModeChange);
    
    return () => {
      window.removeEventListener('darkModeChange', handleDarkModeChange);
    };
  }, []);

  useEffect(() => {
    const carouselElement = document.getElementById('partnersCarousel');
    if (carouselElement && window.bootstrap) {
      new window.bootstrap.Carousel(carouselElement, {
        interval: 2000,
        wrap: true,
        ride: 'carousel'
      });
    }
  }, []);

  return (
    <div className="container-flex pt-0">
      <div className="row mb-5 pt-5 pb-5">
        <div className="col col-xl-7 col-sm-9 col-md-9 col-lg-5 mt-5 mb-5 mx-auto" id='header'>
          <div className='row d-flex mt-5 mb-2'>
            <div className="col-auto animate-slide-left">
              <p className="bg-dark custom-size text-white ps-4 pe-4 rounded">NO CODE</p>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-5 flex-grow-1 animate-slide-right">
              <p className="fs-1 text-uppercase lh-1 text-secondary">NFT collection generator tools</p>
            </div>
          </div>
          <div className="row mb-5 animate-fade-up animation-delay-1">
            <p className="h4 col-12 text-secondary">
              We created a number of <span className='text-dark text-decoration-underline'>utility tools to help</span> non coded artists to <span className='text-dark fw-bold'>easily create NFT </span>collections or extend the lifecycle of existing collections.
            </p>
            <div className="d-flex pt-5 animate-fade-in animation-delay-2">
              <button onClick={handleGetStarted} className="btn btn-lg btn-dark me-3">Get Started</button>
              <button onClick={scrollToApplyForm} className="btn btn-lg btn-outline-dark text-dark fw-bold my-button"><i className="bi bi-heart-pulse-fill me-2"></i>
              I need utility!</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center pt-3">
        <div className="col-12 col-md-4 d-flex flex-column mb-4">
          <div className='p-0 my-2 flex-grow-1'>
            <img src={isDarkMode ? forArtistsImageBlack : forArtistsImage} alt="artist" className="img-fluid mx-auto d-block" />
            <ul className="list-unstyled mt-4 text-secondary">
              <li className='mb-2'><i className="bi bi-check-square me-2"></i>Preview and edit your NFT collection</li>
              <li className='mb-2'><i className="bi bi-check-square me-2"></i>Automatically assign traits to your NFTs</li>
              <li className='mb-2'><i className="bi bi-check-square me-2"></i>Download ready to use metadata files</li>
              <li className='mb-2'><i className="bi bi-check-square me-2"></i>Combine images to generate ready-to-use NFTs.</li>
              <li className='mb-2'><i className="bi bi-check-square me-2"></i>Batch rename files</li>
              <li className='mb-2 text-muted small'>and much more...</li>
            </ul>
            <button onClick={handleGetStarted} className="btn btn-dark btn-lg mt-3">Create your collection</button>
          </div>
        </div>

        <div className="col-12 col-md-8 d-flex flex-column mb-4">
          {/* <h5 className='fs-3 lh-1 text-dark'>Create NFTs Without Coding!</h5>
          <p className='fs-5 mt-2 text-secondary'><strong>Bad Art App</strong> is a tool that simplifies the process of generating your NFT and metadata files . Relax and focus on creating your art...  let our take care of the rest!</p> */}
          <div className='custom-border rounded p-3 my-2 flex-grow-1'>
            <img src="/img/app_screen.jpg" alt="App Screen" className="img-fluid mx-auto d-block" />
          </div>
        </div>
      </div>

      <div className="row justify-content-center pt-5">
        <div className="col-12 col-md-8 d-flex flex-column mb-4">
          <h5 className='fs-3 lh-1 text-dark'>No utility? No problem!</h5>
          <p className='fs-5 mt-2 text-secondary'>We created this app with the intention of sharing its utility with other NFT projects. Fill out the form and let the community decide if your collection should be added to featured collections. </p>
          <div className="custom-border rounded" ref={applyFormRef}>
            <ApplyForm />
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex flex-column mb-4">
          <div className='p-3 my-2 flex-grow-1'>
            <img src={isDarkMode ? forCollectionsImageBlack : forCollectionsImage} alt="collections" className="img-fluid mx-auto d-block" />
          </div>
        </div>
      </div>

      <div className="row custom-portfolio mt-5">
        <div className="col-12">
          <h4 className='fs-3 lh-1 text-dark'>Recently added NFT collections</h4>
          <div className="d-flex flex-wrap mt-4">
            {collectionAddresses.map((collection, index) => (
              collection.collectionAddr ? (
                <TokenDisplay
                  key={index}
                  starsAddress="" // Changed from null to empty string
                  collectionAddr={collection.collectionAddr}
                  title={collection.title}
                  cover={collection.cover}
                  link={collection.link}
                  multiplier={collection.multiplier}
                  updateTotalCount={() => {}} // Add empty callback
                  updateIsOwned={() => {}} // Add empty callback
                  hideTokenCount={true} // Hide token count for homePage.js
                  hideMultiplier={true} // Add this prop
                />
              ) : null
            ))}
            <div className="col-5 col-md-2 float-start">
              <article className={'card text-center custom-border border-bottom-0'}>
                <div className="card-body bg-light p-0 d-flex flex-column">
                  <img className="rounded-top img-fluid" src={empty_bg} alt="empty" loading="lazy" />
                  <div className="card-body p-0 d-flex flex-column">
                    <p className="card-title fw-bold mt-4 mb-5 fs-5">Your collection here</p>
                    <a href="/#/apply" className="btn bg-dark col-12 text-white rounded-top-0" role="button">Apply form</a>                        
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
