import React, { useState, useEffect } from 'react';
import { resizeImage } from '../ext/helpers';
import { BADART_URL } from '../config';
import forCollectionsImage from '../img/for-collections.jpg';
import forCollectionsImageBlack from '../img/for-collections-black.jpg';

const ThankYouScreen = () => (
    <div className="text-center py-5">
        <h2 className="mb-4">Thank You for Your Application!</h2>
        <div className="mb-4">
            <i className="bi bi-check-circle-fill text-success" style={{ fontSize: '4rem' }}></i>
        </div>
        <p className="lead mb-3">Your application has been successfully submitted.</p>
        <div className="alert alert-info" role="alert">
            <i className="bi bi-info-circle me-2"></i>
            Current Status: <strong>Pending Review</strong>
        </div>
        <p className="text-muted">
            We will review your application and get back to you soon.
        </p>
    </div>
);

const ApplyForm = ({ starsAddress }) => {
    const [formData, setFormData] = useState({
        name: '',
        twitter: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

    useEffect(() => {
        // Set initial dark mode
        setIsDarkMode(localStorage.getItem('darkMode') === 'true');

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            setIsDarkMode(e.detail);
        };

        window.addEventListener('darkModeChange', handleDarkModeChange);
        
        return () => {
            window.removeEventListener('darkModeChange', handleDarkModeChange);
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        // Validate required fields
        if (!formData.name) {
            setError('Please fill in project name');
            setLoading(false);
            return;
        }

        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key].trim());
            });

            const response = await fetch(`${BADART_URL}/submit_application.php`, {
                method: 'POST',
                body: formDataToSend
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to submit application');
            }

            setSuccess(true);
            setFormData({
                name: '',
                twitter: ''
            });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid p-4">
            <div className="row">
                <div className="col-12">
                    <div className="bg-transparent">
                        <div className="container-fluid p-0">
                            {error && (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            )}
                            
                            {success ? (
                                <ThankYouScreen />
                            ) : (
                                <form onSubmit={handleSubmit} className="apply-form-container">
                                    <div>
                                        <strong>Hello!</strong> <br />our project name is{' '}
                                        <input
                                            type="text"
                                            className="apply-form-input"
                                            id="name"
                                            name="name"
                                            autoComplete="off"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        We would like to have some of that <strong> sweet utilities</strong> you have here.<br />
                                        You can find us on Twitter as @{' '}
                                        <input
                                            type="text"
                                            className="apply-form-input"
                                            id="twitter"
                                            name="twitter"
                                            autoComplete="off"
                                            value={formData.twitter}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    
                                    <div>
                                        <button 
                                            type="submit" 
                                            className="btn btn-dark apply-form-submit mt-4"
                                            disabled={loading}
                                        >
                                            {loading ? 'Submitting...' : 'Submit Application'}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplyForm;