import { resizeImage } from '../ext/helpers';
import { BADART_URL } from '../config';

export const handleSubmitCollection = async ({
  e,
  name,
  description,
  coverPhoto,
  starsAddress,
  ownedCollectionsCount,
  resetForm,
  showMessage,
  refreshCollectionList
}) => {
  e.preventDefault();
  
  try {
    const limitResponse = await fetch(`${BADART_URL}/api.php?walletAdd=${starsAddress}`);
    if (!limitResponse.ok) throw new Error('Failed to check collection limit');
    const existingCollections = await limitResponse.json();
    
    // Modified to account for free collection
    if (existingCollections.length >= (ownedCollectionsCount + 1)) {
      showMessage(
        `You've reached your limit of ${ownedCollectionsCount + 1} collections (including 1 free collection). Please remove an existing collection before adding a new one.`,
        'error'
      );
      document.querySelector('.collection-list')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    const formData = new FormData();
    formData.append('name', name.trim());
    formData.append('description', description ? description.trim() : '');
    formData.append('walletAdd', starsAddress);
    
    if (coverPhoto) {
      try {
        const resizedImage = await resizeImage(coverPhoto, 300);
        formData.append('coverPhoto', resizedImage);
      } catch (error) {
        console.error('Error resizing image:', error);
        formData.append('coverPhoto', coverPhoto);
      }
    }

    const response = await fetch(`${BADART_URL}/add_collection.php`, {
      method: 'POST',
      body: formData,
    });

    let data;
    const contentType = response.headers.get('content-type');
    try {
      data = contentType?.includes('application/json') 
        ? await response.json()
        : { message: await response.text() };
    } catch (error) {
      console.error('Response parsing error:', error);
      throw new Error('Invalid server response');
    }

    if (!response.ok) {
      throw new Error(data.message || data.error || 'Server error');
    }

    if (data.message === "Collection added successfully") {
      resetForm();
      refreshCollectionList();
      showMessage(data.message, 'success');
    } else {
      showMessage(data.message || 'Unknown error', 'error');
    }
  } catch (error) {
    console.error('Error adding collection:', error);
    showMessage(error.message || 'Error adding collection', 'error');
  }
};

export const handleCoverPhotoChange = ({ file, setCoverPhoto, setCoverPhotoPreview }) => {
  setCoverPhoto(file);
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setCoverPhotoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  } else {
    setCoverPhotoPreview(null);
  }
};
