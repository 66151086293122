import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import badArtLogo from '../img/logo.png';
import badArtLogoBlack from '../img/logo-black.png';

const Footer = () => {
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

    useEffect(() => {
        setIsDarkMode(localStorage.getItem('darkMode') === 'true');
        
        const handleDarkModeChange = (e) => {
            setIsDarkMode(e.detail);
        };

        window.addEventListener('darkModeChange', handleDarkModeChange);
        return () => {
            window.removeEventListener('darkModeChange', handleDarkModeChange);
        };
    }, []);

    return (
        <footer className="pt-3 mb-5">
            <div className="container-fluid">
                <div className="row">
                    {/* Main Content Column */}
                    <div className="col-12 col-md-4 ps-5">
                        <p className="text-own small">&copy; {new Date().getFullYear()} Only Bad Art. All rights reserved.<br />Made on a blunt in the underground.<br /><strong className='mt-3 d-block'>Baked by:</strong></p>
                        <a href='https://only-bad.art' target='_blank' rel='noreferrer'>
                            <img 
                                src={isDarkMode ? badArtLogoBlack : badArtLogo} 
                                alt="Only Bad Art" 
                                className="img-fluid" 
                                style={{ maxWidth: '150px' }}
                            />
                        </a>
                    </div>

                    <div className="col-12 col-md-4">
                        <h5 className="mb-3">Links</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <a href="/" className="text-own text-decoration-none">Home</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://only-bad.art/" target="_blank" rel="noreferrer" className="text-own text-decoration-none">Only Bad Art Project</a>
                            </li>
                            <li className="mb-2">
                                <Link to="/recent-collections" className="text-own text-decoration-none">
                                    All featured collections
                                </Link>
                            </li>
                            <li className="mb-2">
                                <a href="/faq" target="_blank" rel="noreferrer" className="text-own text-decoration-none">NFT Academy</a>
                            </li>
                        </ul>
                    </div>

                    {/* Social Links Column */}
                    <div className="col-12 col-md-4">
                        <h5 className="mb-3">Connect With Us</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <a href="https://x.com/only_bad_art" target="_blank" rel="noreferrer" className="text-own text-decoration-none">
                                    <i className="bi bi-twitter-x me-2"></i>Twitter
                                </a>
                            </li>
                            {/* <li className="mb-2">
                                <a href="https://discord.gg/onlybadart" target="_blank" rel="noreferrer" className="text-own text-decoration-none">
                                    <i className="bi bi-discord me-2"></i>Discord
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://github.com/only-bad-art" target="_blank" rel="noreferrer" className="text-own text-decoration-none">
                                    <i className="bi bi-github me-2"></i>GitHub
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
