import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { handleSubmitCollection, handleCoverPhotoChange } from './collectionHandlers';

const AddCollectionForm = ({ starsAddress, totalCount, ownedCollectionsCount, refreshCollectionList, showMessage }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
  const fileInputRef = useRef(null);

  const resetForm = () => {
    setName('');
    setDescription('');
    setCoverPhoto(null);
    setCoverPhotoPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    // Remove unused code
    // const storedAddress = sessionStorage.getItem('starsAddress');
  }, []);

  const onSubmit = (e) => {
    handleSubmitCollection({
      e,
      name,
      description,
      coverPhoto,
      starsAddress,
      ownedCollectionsCount,
      resetForm,
      showMessage,
      refreshCollectionList
    });
  };

  const onCoverPhotoChange = (e) => {
    handleCoverPhotoChange({
      file: e.target.files[0],
      setCoverPhoto,
      setCoverPhotoPreview
    });
  };

  return (
    <div className="container-fluid mb-5">
      <div className="row">
        <div className="col-12">
          <h3 className="mb-2">Add new collection</h3>
          <p className='fs-6 pt-3'>
            You can create up to: <span className="me-1 fs-6 ms-1 fw-bold badge bg-primary">
              {ownedCollectionsCount}
            </span> NFT collections (including 1 free collection).
          </p>
          <form onSubmit={onSubmit} className='pt-3'>
            <div className="form-group rounded custom-border p-3">
              <input
                type="text"
                className="form-control mb-3"
                id="name"
                value={name}
                maxLength={255}
                autoComplete="off"
                placeholder="Collection Name"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <textarea
                className="form-control mb-3"
                id="description"
                value={description}
                maxLength={255}
                placeholder="Collection Description"
                onChange={(e) => setDescription(e.target.value)}
                rows="3"
              />
              <input
                type="file"
                className="form-control"
                id="coverPhoto"
                accept="image/*"
                onChange={onCoverPhotoChange}
                ref={fileInputRef}
              />
              {coverPhotoPreview && (
                <img src={coverPhotoPreview} alt="Cover Preview" className="img-thumbnail mb-3 mt-4" style={{ maxWidth: '150px' }} />
              )}
            </div>
            <button type="submit" className="btn btn-dark text-white mt-3">
              Add new collection
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

AddCollectionForm.propTypes = {
  starsAddress: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  ownedCollectionsCount: PropTypes.number.isRequired,
  refreshCollectionList: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired
};

export default AddCollectionForm;