import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BADART_URL } from '../config';
import { showMessage } from '../ext/helpers';

const FilesList = ({
  uploadedFiles,
  assignedTraits,
  selectedTrait,
  setSelectedTrait,
  handleRemoveTrait,
  handleJsonDownload,
  handleFileDelete,
  getAvailableTraits,
  groupTraitsByMainTrait,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  getPaginatedFiles,
  getTotalPages,
  starsAddress,
  collectionId,
  setMessage,
  setMessageType,
  fetchAssignedTraits
}) => {
  const [assigningTrait, setAssigningTrait] = useState({});
  const [isVerifyingCredits, setIsVerifyingCredits] = useState(true);
  const [creditsLimit, setCreditsLimit] = useState(null);

  useEffect(() => {
    const verifyCredits = async () => {
      try {
        const response = await axios.get(`${BADART_URL}/fetch.php?stars=${starsAddress}`);
        const { credits } = response.data;
        setCreditsLimit(credits);
        
        if (uploadedFiles.length > credits) {
          showMessage(
            setMessage,
            setMessageType,
            `Warning: You have ${uploadedFiles.length} files but only ${credits} credits (including 100 free files). Only the first ${credits} files will be accessible.`,
            'warning'
          );
        }
      } catch (error) {
        console.error('Error verifying credits:', error);
        showMessage(
          setMessage,
          setMessageType,
          'Error verifying credits limit',
          'error'
        );
      } finally {
        setIsVerifyingCredits(false);
      }
    };

    verifyCredits();
  }, [starsAddress, uploadedFiles.length]);

  if (isVerifyingCredits) {
    return (
      <div className="text-center my-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p className="mt-2">Verifying credits...</p>
      </div>
    );
  }

  // Update handleLocalAssignTrait to use showMessage helper
  const handleLocalAssignTrait = async (fileId, valueId) => {
    if (!valueId || assigningTrait[fileId]) return;
    
    setAssigningTrait(prev => ({ ...prev, [fileId]: true }));
    
    try {
      const response = await axios.post(`${BADART_URL}/trait_to_file.php`, {
        assignments: [{
          file_id: fileId,
          value_id: parseInt(valueId)
        }]
      });

      if (response.data.success && response.data.successful > 0) {
        // Clear the selected trait for this file
        setSelectedTrait(prev => {
          const newState = { ...prev };
          delete newState[fileId];
          return newState;
        });

        // Refresh assigned traits
        if (typeof fetchAssignedTraits === 'function') {
          await fetchAssignedTraits();
        }
        
        // Update message handling
        setMessage('Trait assigned successfully');
        setMessageType('success');
      } else {
        const errorMessage = response.data.results?.[0]?.message || 'Failed to assign trait';
        setMessage(errorMessage);
        setMessageType('error');
      }
    } catch (error) {
      console.error('Error assigning trait:', error);
      setMessage(error.response?.data?.message || 'Error assigning trait');
      setMessageType('error');
    } finally {
      setAssigningTrait(prev => {
        const newState = { ...prev };
        delete newState[fileId];
        return newState;
      });
    }
  };

  const renderPagination = () => {
    const totalPages = getTotalPages();
    const maxVisiblePages = 5;
    let pages = [];

    if (totalPages <= maxVisiblePages) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, '...', totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
      }
    }

    return (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex align-items-center">
          <span className="me-2">Items per page:</span>
          <select 
            className="form-select form-select-sm w-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            {[10, 20, 50, 100].map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        <nav aria-label="File list pagination">
          <ul className="pagination mb-0 rounded bg-light">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button 
                className="page-link my-size-2 bg-transparent" 
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              >
                Previous
              </button>
            </li>
            
            {pages.map((page, index) => (
              <li 
                key={index} 
                className={`page-item ${currentPage === page ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}
              >
                <button 
                  className="page-link"
                  onClick={() => typeof page === 'number' && setCurrentPage(page)}
                >
                  {page}
                </button>
              </li>
            ))}

            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button 
                className="page-link" 
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  return (
    <>
      {creditsLimit !== null && uploadedFiles.length > creditsLimit && (
        <div className="alert alert-warning mb-3">
          <i className="bi bi-exclamation-triangle me-2"></i>
          You have {uploadedFiles.length} files but only {creditsLimit} credits (including 100 free files). Only the first {creditsLimit} files will be accessible.
        </div>
      )}
      
      <ul className="list-group">
        {getPaginatedFiles().map((file, index) => (
          <li className='list-group-item bg-light' key={file.id}>
            <div className='row align-items-center'>
              <div className='col-auto'>
                <span className='small bg-light text-secondary'>
                  {((currentPage - 1) * pageSize) + index + 1}
                </span>
              </div>
              <div className='col-1 col-md-auto img-resize'>
                <img 
                  src={`${BADART_URL.replace('/api', '')}/uploads/collections/${starsAddress}/${collectionId}/${file.file_name}`} 
                  alt={file.file_name} 
                  width="100" 
                  className="img-fluid"
                />
                <img 
                  src={`${BADART_URL.replace('/api', '')}/uploads/collections/${starsAddress}/${collectionId}/${file.file_name}`} 
                  alt={file.file_name} 
                  width="250" 
                  className="img-fluid d-none img-zoom"
                />
              </div>
              <div className='col-7 col-md-5'>
                <div className="assigned-traits">
                  {assignedTraits[file.id] && Object.entries(groupTraitsByMainTrait(assignedTraits[file.id])).map(([traitName, values], idx) => (
                    <div key={idx} className="trait-group mt-1 mb-1">
                      <strong className="d-inline me-1">{traitName}:</strong>
                      <div className="d-inline-flex flex-wrap gap-1">
                        {values.map((assignment, valueIdx) => (
                          <span key={valueIdx} className="badge small bg-secondary d-flex align-items-center ms-1">
                            {assignment.value.split(' ').slice(0, 3).join(' ')}{assignment.value.split(' ').length > 3 ? '...' : ''}
                            <button 
                              className="btn-close btn-close-white ms-2"
                              style={{ fontSize: '0.5rem' }}
                              onClick={() => handleRemoveTrait(file.id, assignment.value_id)}
                            ></button>
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='col-auto ms-auto'>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="trait-assignment me-2">
                    {getAvailableTraits(file.id).length > 0 ? (
                      <>
                        <select 
                          className='form-select d-inline-block w-auto me-2'
                          value={selectedTrait[file.id] || ''}
                          onChange={(e) => setSelectedTrait({...selectedTrait, [file.id]: e.target.value})}
                          disabled={assigningTrait[file.id]}
                        >
                          <option value="">Select trait</option>
                          {getAvailableTraits(file.id).map((trait) => (
                            trait.values && trait.values.length > 0 ? (
                              <optgroup key={trait.id} label={trait.trait_name}>
                                {trait.values.map(value => (
                                  <option key={value.id} value={value.id}>
                                    {value.value.split(' ').slice(0, 3).join(' ')}{value.value.split(' ').length > 3 ? '...' : ''}
                                  </option>
                                ))}
                              </optgroup>
                            ) : null
                          ))}
                        </select>
                        <button 
                          className='btn btn-sm btn-dark'
                          disabled={!selectedTrait[file.id] || assigningTrait[file.id]}
                          onClick={() => handleLocalAssignTrait(file.id, selectedTrait[file.id])}
                        >
                          {assigningTrait[file.id] ? (
                            <i className="bi bi-hourglass-split"></i>
                          ) : (
                            <i className="bi bi-plus-circle-dotted"></i>
                          )}
                        </button>
                      </>
                    ) : (
                      assignedTraits[file.id]?.length > 0 ? (
                        <span className="text-success">
                          <i className="bi bi-check-all me-1"></i>
                        </span>
                      ) : null
                    )}
                  </div>
                  <button 
                    className='btn btn-primary btn-sm text-white me-2'
                    onClick={() => handleJsonDownload(file, ((currentPage - 1) * pageSize) + index + 1)}
                  >
                    <i className="bi bi-file-earmark-arrow-down"></i>
                  </button>
                  <button className='btn btn-danger btn-sm' onClick={() => handleFileDelete(file.file_name)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {renderPagination()}
    </>
  );
};

export default FilesList;