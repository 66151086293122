import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';  // Add this import
import { BADART_URL } from '../config';
import Toast from '../components/Toast';  // Add this import

const TicketSystem = ({ starsAddress }) => {  // Add starsAddress prop
  // Add toast state
  const [toast, setToast] = useState({ message: '', type: '' });
  
  const [ticket, setTicket] = useState({
    title: '',
    description: '',
    priority: 'low',
    status: 'open',
    images: []
  });
  
  const [tickets, setTickets] = useState([]);

  const fetchTickets = useCallback(async () => {
    try {
      const response = await fetch(`${BADART_URL}/get-tickets.php?starsAddress=${starsAddress}`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setTickets(data.tickets || []);
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  }, [starsAddress]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const showToast = (message, type) => {
    setToast({ message, type });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      showToast('Maximum 5 images allowed', 'error');
      return;
    }
    setTicket(prev => ({ ...prev, images: files }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', ticket.title);
      formData.append('description', ticket.description);
      formData.append('priority', ticket.priority);
      formData.append('status', ticket.status);
      formData.append('starsAddress', starsAddress);  // Add starsAddress
      
      // Append images to formData
      ticket.images.forEach((image, index) => {
        formData.append(`image${index}`, image);
      });

      const response = await fetch(`${BADART_URL}/create-ticket.php`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      const data = await response.json();
      
      if (response.ok) {
        showToast(data.message, 'success');
        setTicket({
          title: '',
          description: '',
          priority: 'low',
          status: 'open',
          images: []
        });
        fetchTickets();
      } else {
        throw new Error(data.message || 'Failed to create ticket');
      }
    } catch (error) {
      console.error('Error creating ticket:', error);
      showToast(`Failed to create ticket: ${error.message}`, 'error');
    }
  };

  const getStatusBadgeClass = (status) => {
    const classes = {
      pending: 'bg-warning',
      closed: 'bg-secondary',
      replied: 'bg-success',
      open: 'bg-primary'
    };
    return `badge ${classes[status] || 'bg-primary'}`;
  };

  const getPriorityIcon = (priority) => {
    const icons = {
      low: 'bi bi-arrow-down-circle text-success',
      medium: 'bi bi-dash-circle text-warning',
      high: 'bi bi-exclamation-circle text-danger'
    };
    return icons[priority] || icons.low;
  };

  return (
    <div className="container-flex mt-4">
      {/* Add Toast component at the top */}
      <Toast 
        message={toast.message} 
        type={toast.type} 
        onClose={() => setToast({ message: '', type: '' })} 
      />
      
      <div className="row">
        <div className="col-12 col-md-4">
        <h3 className="mb-4">Create New Ticket</h3>
        <div className="card bg-transparent custom-border">
          <div className="card-body">
              <form onSubmit={handleSubmit} className="needs-validation">
                <div className="mb-3">
                  <label className="form-label">Title:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={ticket.title}
                    onChange={(e) => setTicket({...ticket, title: e.target.value})}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description:</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={ticket.description}
                    onChange={(e) => setTicket({...ticket, description: e.target.value})}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Priority:</label>
                  <select
                    className="form-select"
                    value={ticket.priority}
                    onChange={(e) => setTicket({...ticket, priority: e.target.value})}
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Images (Max 5):
                    <i className="bi bi-info-circle ms-2" title="Supported formats: JPG, PNG, GIF. Max size: 2MB each"></i>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    max="5"
                  />
                  {ticket.images.length > 0 && (
                    <div className="mt-2">
                      <small className="text-muted">
                        Selected {ticket.images.length} image(s)
                      </small>
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-dark">Create Ticket</button>
              </form>
            </div>
          </div>
        </div>
        
        <div className="col-12 col-md-8">
        <h4 className="mb-4"><i className="bi bi-ticket-perforated me-2"></i>Your Tickets</h4>
        <div className="card bg-transparent custom-border">
            <div className="card-body">
              <div className="list-group">
                {tickets.map((ticket, index) => (
                  <div key={index} className="list-group-item list-group-item-action border-start border-4 border-light">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <h5 className="mb-1">
                        <i className="bi bi-chat-left-text me-2"></i>
                        {ticket.title}
                      </h5>
                      <span className={getStatusBadgeClass(ticket.status)}>
                        <i className="bi bi-clock me-1"></i>
                        {ticket.status}
                      </span>
                    </div>
                    <p className="mb-2 text-muted small border-start border-2 ps-2 mt-2">
                      {ticket.description}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <small className="text-muted">
                        <i className={`${getPriorityIcon(ticket.priority)} me-1`}></i>
                        Priority: {ticket.priority}
                      </small>
                      <small className="text-muted">
                        <i className="bi bi-calendar-event me-1"></i>
                        {ticket.created_at || 'Just now'}
                      </small>
                    </div>
                  </div>
                ))}
                {tickets.length === 0 && (
                  <div className="list-group-item text-center text-muted py-5">
                    <i className="bi bi-inbox-fill fs-1 d-block mb-2"></i>
                    No tickets found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TicketSystem.propTypes = {
  starsAddress: PropTypes.string
};

export default TicketSystem;
